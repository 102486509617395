.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: palegreen;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40a4cb;
}

@media (min-width: 500px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
  /* color of the letters */
  .NavigationItem a {
    color: palegreen;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }
  /* button when hovering over */
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: lightblue;
    border-bottom: 4px solid #40a4cb;
    color: navy;
  }
}
