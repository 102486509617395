@import url("https://fonts.googleapis.com/css?family=Berkshire+Swash&family=Roboto|Roboto&display=swap");

.NotFound {
  color: rebeccapurple;
  margin-top: 100px;
}

.Jumbotron {
    margin-top: 56px;
    font-family: "Berkshire Swash", cursive;
    font-size: 20px;
    text-shadow: 0 0 10px rgb(51, 4, 73), 0 0 13px #3766bd, 0 0 20px #f11861;
  }

  .Background {
    height: 450px;
    max-width: 100%;
    background-position: -10px 0px;
    display: block;
  }