@import url("https://fonts.googleapis.com/css?family=Berkshire+Swash&family=Roboto|Roboto&display=swap");

.Home {
  color: lightblue;
  font-family: "Berkshire Swash", cursive;
  font-size: xx-large;
  height: 300px;

  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Jumbotron {
  margin-top: 56px;
  font-family: "Berkshire Swash", cursive;
  font-size: 20px;
  text-shadow: 0 0 10px rgb(51, 4, 73), 0 10 13px #3766bd, 0 0 20px #f11861;
}

.Background {
  height: 450px;
  max-width: 100%;
  background-position: -10px 0px;
  display: block;
}

.ProfilePic {
  overflow: hidden;
  margin: 0px;
}

.ProfilePic img {
  object-fit: cover;
  border-radius: 100%;
  width: 160px;
  height: 200px;
}
